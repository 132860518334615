import React from "react"
import "./HeaderSplash.css"
import Bg from "./bg.jpg"

export default function HeaderSplash() {
  return (
    <div
      className="HeaderSplash"
      style={{ backgroundImage: `url(${Bg})` }}
    ></div>
  )
}
