import React from "react"
import "./HeaderAvatar.css"
// import Avatar from "../../../content/images/avatar-april-2022.jpg"
import Avatar from "../../../content/images/avatars/anselm-spring-2016.jpg"

export default function HeaderAvatar() {
  return (
    <div
      className="HeaderAvatar"
      style={{ backgroundImage: `url(${Avatar})` }}
    ></div>
  )
}
